
import store from "@/store";
import { defineComponent } from 'vue';
import { Button, Searchbar, Table} from '@/ui/index';
import { DepartmentController } from '@/controller/';


export default defineComponent({
    name: "DepartmentList",
    components: { Button, Searchbar, Table },
    data() {
        return {    
            store,
            departmentTableData: {
                thFields: [{title: this.$t("department.number"), property: 'number', type: 'string' }, { title: this.$t("department.title"), property: "title", type: "string", clickAction: 'openDetail'}],
                tbActions: [{ name: "openDetail", icon: store.getters.svgIcons.watch }, { name: "editDepartment", icon: store.getters.svgIcons.pencil }, { name: "confirmDelete", icon: store.getters.svgIcons.remove }]
            },
            departments: [] as Array<any>,
            searchInput: ""
        }
    },
    created(){
        this.handleGetDepartments();
    },
    computed: {
        filteredDepartments() {
            let filtered = [] as Array<any>;
            if (this.searchInput) {
                this.departments.forEach((department : any) => {
                    JSON.stringify(department.number).toLowerCase().includes(this.searchInput.toLowerCase()) && filtered.push(department);
                    !filtered.includes(department) && JSON.stringify(department.title).toLowerCase().includes(this.searchInput.toLowerCase()) && filtered.push(department);
                })
            } else {
                filtered = this.departments;
            }
            return filtered;
        }
    },
    methods:{
        async handleGetDepartments() {
            const res = await DepartmentController.fetchDepartments();
            if(!res.error) {
                this.departments = res.items;
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        handleTableActionClicked(action : string, item:any) {
            switch(action) {
                case "openDetail":
                    this.handleOpenDetailView(item.uid);
                    break
                case "editDepartment": 
                    this.handleEdit(item.uid);
                    break
                    case "confirmDelete":
                    this.handleConfirmDelete(item);
                    break
            }
        },
        handleOpenDetailView(uid : number) {
            this.$router.push("/administration/department/detail/" + uid);
        },
        handleShowCreateForm() {
            this.$router.push('/administration/department/form/0');
        },
        handleEdit(uid:number) {
            this.$router.push("/administration/department/form/" + uid);
        },
        handleConfirmDelete(item : any) {
            this.$confirm(this.$t("confirm.delete"), this.$t('confirm.deleteDepartmentText', { name: item.title }), {
                labelConfirm: this.$t("button.delete"),
                onConfirm: () => { this.handleDelete(item.uid) },
                //onCancel: () => { console.log("onCancel"); }
           });
        },
        async handleDelete(uid : number) {
            const res = await DepartmentController.deleteDepartment(uid);
            if (!res.error) {
                this.departments = this.departments.filter(function(item : any) {
                   return item.uid != res.workarea.uid;
                });
                this.$notify(this.$t("success.departmentDeleted"), { position: "top" });
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        }
    }

})
