import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "timi-department-container" }
const _hoisted_2 = { class: "top-action-bar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Searchbar = _resolveComponent("Searchbar")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Table = _resolveComponent("Table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Searchbar, {
        onOnValueChange: _cache[0] || (_cache[0] = (input) => _ctx.searchInput = input)
      }),
      _createVNode(_component_Button, {
        text: _ctx.$t('button.addDepartment'),
        primary: true,
        raised: true,
        onOnClick: _ctx.handleShowCreateForm
      }, null, 8, ["text", "onOnClick"])
    ]),
    (_ctx.departments.length)
      ? (_openBlock(), _createBlock(_component_Table, {
          key: 0,
          tableData: _ctx.departmentTableData,
          src: _ctx.filteredDepartments,
          onOnActionClicked: _ctx.handleTableActionClicked
        }, null, 8, ["tableData", "src", "onOnActionClicked"]))
      : _createCommentVNode("", true)
  ]))
}